<template>
  <div class="home panel">
    <div class="inner">
      <MainHeader :isDirty="isDirty" :isBroken="isBroken" ref="header" :readonly="true" />
      <EditorView @dirty="onDirty" @broken="onBroken" ref="editor" class="view"  :readonly="true"  v-if="ready === 'ok'" />
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader'
import EditorView from '@/components/EditorView'
import MainPanel from '@/components/MainPanel'
import {mapMutations} from 'vuex'

export default {
  name: 'Home',
  data(){
    return{
      ready:null,
      isDirty:false,
      isBroken:false
    }
  },
  components: {
    MainHeader,EditorView,MainPanel
  },
  mounted(){
    const endpt = process.env.VUE_APP_CDN_FRONTEND_BASE
    const uid = this.$route.params.id
    
    if(uid){
      this.ready = 'loading'

      fetch(endpt + uid + '.json')
        .then(resp => resp.json())
        .then(p => {
            let env = p._source.env
            this.setEnv(env)
            this.setCode(p._source[env])
            this.setProject(p)
            this.ready = 'ok'
            this.readonly = true

        }).catch(err => {
            console.log(err)
        })
    }
  },
  methods:{
    ...mapMutations(['setCode', 'setProject', 'setEnv', 'setBase']),
    onDirty(){
      this.isDirty = true
    },
    onBroken(v){
      this.isBroken = v
    }
  }
}
</script>



<style scoped>
.view{
  flex:1;
}
</style>