import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import PresentaControllerStyles from '@presenta/controller-styles'

import * as Presenta from '@presenta/lib'

/*
npm i file://../presenta-lib  file://../md2pjson file://../txt2pjson --no-save
*/

import './registerServiceWorker'

Presenta.use(PresentaControllerStyles)

Vue.config.productionTip = false

// this allows to install plugins from config
window.Presenta = Presenta

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
