<template>
    <div class="container">
        <div class="preso" ref="presenta"></div> 
        <transition name="fade">
            <div v-if="toasts" class="toast top"><span>ESC key or Back to prev mode</span></div>
        </transition>
    </div>
</template>



<script>
import * as Presenta from '@presenta/lib'
import { mapGetters } from 'vuex'
import stringify from 'json-stringify-safe'

export default {
    props:{
        prj:Object
    },
    computed:{
        ...mapGetters(['project', 'showIndex'])
    },
    data(){
        return{
            toasts:true
        }
    },
    mounted(){
        const prj = this.prj || this.project
        let p = JSON.parse(stringify(this.project))

        if(!p.controllers) p.controllers = {}
        p.controllers.focus = true
        p.controllers.swiper = true
        p.controllers.preload = true
        p.controllers.current = this.showIndex
        
        p.adapt = true
        new Presenta(this.$refs.presenta, p)

        setTimeout(() => {
            this.toasts = false
        }, 5000)

        document.body.addEventListener('keyup', this.keyListen)
    },
    beforeDestroy(){
        document.body.removeEventListener('keyup', this.keyListen)
    },
    methods:{
        keyListen(e){
            if(e.key === 'Escape'){
                this.$router.back()
            }
        }
    }
}
</script>




<style scoped>
.container{
    position: relative;
    height: 100%;
}
.preso{
    height: 100%;
}
.preso >>> div:focus {
  outline: none;
}

.toast{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding:1rem;
    pointer-events: none;
}

.bottom{
    align-items: flex-end;
}

.toast span{
    padding:1rem;
    background-color: black;
    color:white;
    border:1px solid white;
    border-radius: 5px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>