<template>
    <div class="view panel">
        <div class="inner">
            <MainHeader :prj="prj" />
            <div class="body">
            <div class="left">
                <div class="preso" ref="presenta"></div>
            </div>
            <div class="right">
                <h1>{{title}}</h1>    
                <p v-if="description">{{description}}</p>    
                <p class="when">Created {{created | fromNow}}</p>

                <div class="tools">
                    <button id="copied" title="copy URL" @click="copyLink">{{copyLabel}}</button>  <span v-if="copied">👏</span>
                </div>
                
            </div> 
            </div>

            <div class="loading" v-if="!loaded">Fetching...</div>
        
            <textarea id="vclipboard" style="position:absolute;top:-1000px;"></textarea>

        </div>
    </div>
</template>



<script>
import MainHeader from '@/components/MainHeader'
import * as Presenta from '@presenta/lib'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mapMutations } from 'vuex'
dayjs.extend(relativeTime)

export default {
    components:{
        MainHeader
    },
    data(){
        return{
            title:'',
            description:'',
            created: '',
            copied:false,
            shareLink: '',
            loaded:false,
            prj:null
        }
    },
    filters:{
        fromNow(v){
            const d = v ? dayjs(v) : null
            return d ? d.fromNow() : ''
        }
    },
    computed:{
        copyLabel(){
            return this.copied ? 'copied' : 'copy link'
        }
    },
    mounted(){
        const endpt = process.env.VUE_APP_CDN_FRONTEND_BASE
        const uid = this.$route.params.id

        fetch(endpt + uid + '.json')
            .then(resp => resp.json())
            .then(p => {
                p.adapt = true
                new Presenta(this.$refs.presenta, p)

                this.created = p.created_at
                this.title = p.title || 'Untitled'
                this.description = p.description
                document.title = this.title + ' | PRESENTA Play!'
                this.shareLink = process.env.VUE_APP_PLAY_DOMAIN + '/view/' + p.filename

                this.prj = p
                this.loaded = true
            }).catch(err => {
                console.log(err)
                this.$refs.presenta.innerHTML = '<p>There is an error</p>'
            })
    },
    methods:{
        ...mapMutations(['setProject']),
        copyLink(){
            const clip = document.querySelector('#vclipboard')
            clip.value = this.shareLink
            clip.select()
            document.execCommand('copy')
            this.copied=true
        },
        open(){
            this.$router.push('/peek/' + this.$route.params.id)
        },
        present(){
            this.setProject(this.prj)
            this.$router.push('/present')
        }
    }
}
</script>



<style scoped>
.view{
    position: relative;
    height: 100%;
}

.body{
    display: flex;
    flex-direction: column;
}
.left, .right{
    padding: 1rem;
}

h1{
    margin: 0;
}
p{
    margin: 0;
    margin:.5rem 0;
}

.when{
    font-size: .9rem;
    font-style: italic;
}

.tools button:first-child{
    margin-left:0;
}
.tools{
    margin-top:1rem;
}

.preso{
    width: 100%;
    box-shadow: 0 0 10px #cccccc;
}

.loading{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
    z-index: 999;
    background: white;
}

@media screen and (min-width: 420px){
    
    .body{
        display: flex;
        flex-direction: row;
    }
    .left, .right{
        flex:1;
    }
}
</style>