<template>
    <div class="view panel">
        <div class="message">{{message}}</div>
    </div>
</template>


<script>
import LZ from 'lz-string'
import { mapMutations } from 'vuex'

export default {
    data(){
        return{
            message: 'Converting...'
        }
    },
    mounted(){
        const blob = this.$route.query.id
        if(blob){
            try{
                const md = LZ.decompressFromEncodedURIComponent(blob)
                if(md){
                    this.setEnv('markdown')
                    this.setCode(md)
                    this.$router.push('/edit')
                }else{
                    this.message = 'There was an error with this file'
                    console.log(blob, md)
                }
            }catch(err){
                this.message = 'There was an error with this string'
                console.log(blob)
            }
        }
    },
    methods:{
        ...mapMutations(['setCode', 'setProject', 'setEnv', 'setBase'])
    }
}
</script>



<style scoped>
.message{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:0;
    left:0;
    z-index: 999;
    background: white;
}
</style>