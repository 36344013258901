import * as P from '@presenta/lib'

export default str => {
  const Presenta = P // this expose the global Presenta object into the live coding env
  let project = null
  try {
    project = eval(`(() => {${str}})()`)
  } catch (e) {
    // console.log(e)
  }

  return project
}
