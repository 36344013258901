import yaml from 'yaml'
import marked from 'marked'

const decorateBlock = b => {
  if (!b.type) {
    if (b.text) {
      b.type = 'text'
      b.text = marked(b.text)
    }

    const urls = ['image', 'video', 'youtube', 'embed']
    urls.forEach(t => {
      if (b[t]) {
        b.type = t
        b.url = b[t]
      }
    })
  }
}

export default (str) => {
  let prj = null

  try {
    prj = yaml.parse(str)
  } catch (e) {
    console.log(e)
  }

  let project = null

  if (prj) {
    try {
      project = {
        props: {}
      }

      const scenes = []

      if (Array.isArray(prj)) {
        prj = {
          scenes: prj
        }
      }

      prj.scenes.forEach(_s => {
        _s = _s || {}
        if (typeof _s === 'string') _s = {}
        if (!_s.blocks) {
          _s = { blocks: [_s] }
        }
        scenes.push(_s)
      })

      for (const k in prj) {
        if (k !== 'scenes') {
          project[k] = prj[k]
        }
      }

      scenes.forEach((s, i) => {
        if (!s.props) s.props = {}

        // const blk = s.blocks[0]
        // const layouts = ['stack', 'rows', 'cols']
        // layouts.forEach(t => {
        //   if (blk[t]) {
        //     s.blocks = blk[t]
        //     s.layout = t
        //   }
        // })

        s.blocks.forEach((b, j) => {
          decorateBlock(b)

          // if (b.blocks) {
          //   b.blocks.forEach((bb, l) => {
          //     decorateBlock(bb)
          //   })
          // }
        })
      })

      project.scenes = scenes
    } catch (e) {
      console.log('no good')
    }
  }

  return project
}
