<template>
    <div class="placeholder">
        <div class="inner">

        </div>
    </div>
</template>



<script>
export default {
    props:{
        item:Object
    },
    mounted(){
        let options = {
            root: document.querySelector('#checkarea'),
            rootMargin: '0px',
            threshold: 1.0
        }

        let callback = (entries, observer) => {
            entries.forEach(entry => {
                if(!this.item.loaded && entry.isIntersecting){
                    this.item.loaded = true
                    observer.unobserve(this.$el)
                }
                
            })
        }
        let observer = new IntersectionObserver(callback, options)

        observer.observe(this.$el)
    }
}
</script>




<style scoped>
.placeholder{
    width: 100%;
    height: 250px;
    padding: 1rem;
}
.inner{
    background-color: grey;
}
</style>