import md5 from 'md5'
import stringify from './stringify'

const sid = (i, s, p) => {
  return md5(i + stringify(s) + p._integrity)
}

export default project => {
  if (project && project !== undefined && project.scenes) {
    const ids = []
    project.scenes.forEach((s, i) => {
      s._editing = false
      if (s.blocks) {
        s.blocks.forEach((b, j) => {
          if (b.blocks) {
            b.blocks.forEach((bb, l) => {
              bb._uid = md5(l + stringify(bb))
            })
          }
          b._uid = md5(j + stringify(b))
        })
      }
      let id = sid('', s, project)
      if (ids.indexOf(id) >= 0) id = sid(i, s, project)
      s._uid = id
      ids.push(id)
    })
  }
}
