<template>
    <div class="container">
        <div class="edit">
            <Ace v-model="icode" 
                ref="editor"
                @init="editorInit" 
                :lang="tEnv" 
                theme="chrome"></Ace>
        </div>
        <div class="list">
            <VuePresentaProject @selected="onSelected" @present="onPresent" :project="project" v-if="project" />
        </div>
    </div>
</template>




<script>
import VuePresentaProject from './VuePresentaProject'

import Vue from 'vue'
import {mapGetters, mapMutations} from 'vuex'
import Ace from 'vue2-ace-editor'

import yaml2pjson from '@/libs/yaml2pjson/'
import js2pjson from '@/libs/js2pjson/'
import json2pjson from '@/libs/json2pjson/'
import md2pjson from '@presenta/md2pjson'
import txt2pjson from '@presenta/txt2pjson'

export default {
    props:{
        readonly:Boolean
    },
    components:{
        Ace, VuePresentaProject
    },
    computed:{
        ...mapGetters(['code', 'env', 'project']),
        tEnv(){
            let conv = {text:'markdown'}
            let env = conv[this.env] || this.env
            return env
        }
    },
    async mounted(){
        let ed = this.$refs.editor.editor
        ed.session.setTabSize(2)
        ed.setOptions({
            showPrintMargin: false,
            fontSize: "12pt",
            animatedScroll:true,
            showLineNumbers:false,
            showFoldWidgets:false
        })

        const selection = ed.session.getSelection()
        selection.on('changeCursor', e => {
            if(this.env === 'markdown' || this.env === 'text'){
                const parser = this.env === 'markdown' ? md2pjson : txt2pjson
                const range = ed.getSelectionRange()
                const row = range.start.row

                let sceneNum = 0
                try{
                    sceneNum = parser.findIndex(this.code, row)
                }catch(e){
                    console.log(e)
                }
                if(sceneNum >= 0){
                    const href = location.href
                    const parts = href.split('#')
                    location.href = parts[0] + '#s' + sceneNum
                    if(this.project){
                        let scn = this.project.scenes
                        scn.forEach(s => (s._editing = false))
                        if(sceneNum < scn.length){
                            Vue.nextTick(() => (scn[sceneNum]._editing = true))
                        }
                    }

                    ed.focus()
                }
                this.currentSelected = sceneNum
            }
        })

        this.icode = this.code
    },
    watch:{
        icode(v){
            this.setCode(v)
            
            clearTimeout(this.debounce)
            this.debounce = setTimeout(async () => {

                try{
                    let p = null
                    switch(this.env){
                        case 'yaml':
                            this.setProject(yaml2pjson(v))
                            break

                        case 'javascript': 
                            p = await js2pjson(v)
                            this.setProject(p)
                            break

                        case 'json': 
                            this.setProject(json2pjson(v))
                            break

                        case 'markdown': 
                            p = md2pjson.parse(v)
                            console.log(p)
                            this.setProject(p)
                            
                            break

                        case 'text': 
                            p = txt2pjson.parse(v)
                            this.setProject(p)
                            
                            break
                    }
                    
                    if(!this.readonly) this.save() 

                    if(this.readonly && this.isFirstEdit){
                        this.$emit('dirty')
                    }
                    this.isFirstEdit = true

                    this.$emit('broken', false)
                }catch(e){
                    console.log(e)
                    this.$emit('broken', true)
                }
                
            }, 250)
            
        },
        env(){
            this.loadStarter()
        }
    },
    data(){
        return{
            configs:{
                spellChecker:false,
                status:false,
                hideIcons: ['guide', 'preview', 'side-by-side', 'fullscreen'],
                showIcons: ['code', 'underline', 'strikethrough', 'horizontal-rule'],
            },
            icode:'',
            debounce:null,
            currentSelected:-1,
            isFirstEdit:false
        }
    },
    methods:{
        ...mapMutations(['setCode', 'setProject', 'save', 'setShowIndex']),
        editorInit(){
            require('brace/ext/language_tools')
            require('brace/mode/yaml')
            require('brace/mode/markdown')
            require('brace/mode/javascript')
            require('brace/mode/json')
            require('brace/mode/text')
            require('brace/theme/chrome')
        },
        loadStarter(){
            this.setCode(this.code)
            this.icode = this.code
        },
        onSelected(index){
            if(this.env === 'markdown' || this.env === 'text'){
                const parser = this.env === 'markdown' ? md2pjson : txt2pjson

                //console.log('sel', index)

                let ed = this.$refs.editor.editor
                const range = ed.getSelectionRange()
                const selection = ed.session.getSelection()

                const calcRange = parser.findRange(this.code, index)

                range.start.row = calcRange.start
                range.start.column = 0
                range.end.row = calcRange.end
                range.end.column = 0
                selection.setSelectionRange(range)

                ed.scrollToRow(calcRange.start - 1)
            }
        },
        onPresent(index){
            this.setShowIndex(index + 1)
            this.$router.push('/present')
        }
    }
}
</script>




<style>
.ace_editor{
    font-size:14px;
}
.ace-chrome .ace_gutter{
    background-color: black!important;
    color:#999!important;
}
.ace-chrome .ace_gutter-active-line{
    background-color: #444!important;
}

.list .slide > .wrapper{
    border:10px solid black;
}
</style>




<style scoped>
.container{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
}


.edit{
    flex:1;
    border-right:3px solid black;
    
}

.list{
    width: 100%;
    height: 200px;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior:smooth;
}


@media screen and (min-width:420px) {
    .container{
        flex-direction: row;
    }
    .edit{
        height: 100%;
    }
    .list{
        width: 300px;
        /* width: 50%; */
        height: 100%;
    }
}
@media screen and (min-width:720px) {
    .list{
        width: 400px;
    }
}
</style>
