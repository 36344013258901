<template>
    <div class="projectWrapper">
        <VuePresentaScene 
            @click.native="selectThis(index)"
            @dblclick.native="presentThis(index)"
            v-for="(scene,index) in scenes" 
            :scene="scene" 
            :index="index"
            :project="project"
            :key="scene._uid" />
    </div>
</template>



<script>
import VuePresentaScene from './VuePresentaScene'

export default {
    props:{
        project:Object
    },
    components:{
        VuePresentaScene
    },
    mounted(){
    },
    computed:{
        scenes(){
            // that's really bad!
            return this.project.scenes.filter(d => !d.hidden)
        }
    },
    methods:{
        selectThis(index){
            this.$emit('selected', index)
        },
        presentThis(index){
            this.$emit('present', index)
        }
    }
}
</script>



<style scoped>
.projectWrapper{
    border-right: 2px solid;

    display: flex;
    flex-wrap: wrap;
}

.projectWrapper > div{
    width: 100%;
}

@media screen and (min-width:420px) {
    .projectWrapper > div{
        width: 100%;
    }
}
</style>