<template>
  <div class="home panel">
    <div class="inner" @click="closePanel">
      <MainHeader :isBroken="isBroken" ref="header" @showPanel="onShowPanel" />
      <EditorView @broken="onBroken" ref="editor" class="view" />
      <MainPanel v-if="showPanel"  />
    </div>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader'
import EditorView from '@/components/EditorView'
import MainPanel from '@/components/MainPanel'

export default {
  data(){
    return{
      showPanel: false,
      isBroken: true
    }
  },
  components: {
    MainHeader,EditorView,MainPanel
  },
  methods:{
    onShowPanel(v){
      this.showPanel = v
    },
    closePanel(){
      if(this.showPanel) this.$refs.header.toggleShowPanel()
    },
    onBroken(v){
      this.isBroken = v
    }
  }
}
</script>



<style scoped>
.view{
  flex:1;
}
</style>