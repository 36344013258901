<template>
  <div class="home panel">
    <div id="checkarea" class="inner">
      <div class="innwrapper">

            <div class="build">{{build}} {{version}}</div>

            <div class="ribbon">
              <a href="https://github.com/presenta-software/presenta-lib">GitHub</a>
            </div>
            
            <h1>PRESENTA Playground!</h1>
            <h2><mark>Make slides fast!</mark></h2>

            <div class="cta">
              <p>Pssh! We're working on PRESENTA platform! <br /><a href="https://www.presenta.cc/" target="_blank">Subscribe</a> to get an early access!</p>
            </div>

           
           <div class="main samples" v-if="!empty">
              <div class="grid">
                <div class="sample">
                  <button @click="openLast">Open your last presentation</button>
                </div>
              </div>
            </div>


            <div class="samples" v-for="g in samples" :key="g.label">
              <h2>{{g.label}}</h2>
              <p v-if="!g.shown"><button class="small" @click="g.shown=true">Show {{g.samples.length}} samples</button></p>
              <div class="grid" v-if="g.shown">
                <div v-for="item in g.samples" :key="item.id" class="sample">
                  <!-- <Placeholder :item="item" v-if="!item.loaded" /> -->
                  <!-- <VuePresentaExample :item="item" v-if="item.loaded" /> -->
                  <VuePresentaExample :item="item" />
                </div>
              </div>
            </div>


        </div>
    </div>
  </div>
</template>



<script>
import * as Presenta from '@presenta/lib'
import { mapGetters, mapMutations } from 'vuex'
import VuePresentaExample from '@/components/VuePresentaExample'
import Placeholder from '@/components/Placeholder'
import axios from 'axios'
import dayjs from 'dayjs'

export default {
  components:{
    VuePresentaExample,Placeholder
  },
  computed:{
    ...mapGetters(['empty', 'lastCode', 'lastEnv', 'samples']),
    build(){
      return process.env.VUE_APP_BUILD
    },
    version(){
      return Presenta.version
    }
  },
  async mounted(){
    if(this.samples.length === 0){
      const today = dayjs().format('YYYY-MM-DD')
      const res = await axios(process.env.VUE_APP_CDN_NETLIFY + '/samples.json?t=' + today)
      const samples = res.data.samples
      samples.forEach((s,i) => {
        s.shown = false
        if(i === 0) s.shown = true
        s.samples.forEach(p => {
          p.loaded = false
        })
      })
      this.setSamples(samples)
    }
  },
  methods:{
    ...mapMutations(['setCode', 'setEnv', 'setSamples']),
    openLast(){
      this.setEnv(this.lastEnv)
      this.setCode(this.lastCode)
      this.$router.push('/edit')
    }
  }
}
</script>



<style scoped>
.inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    overflow-y: auto;
}

.cta{
  display: flex;
  align-items: center;
  justify-content: center;
}
.cta p{
  background-color: rgb(255, 236, 131);
  padding: .5rem;
  font-weight: bold;
  margin-top: 2.5rem;
  width: 80%;
}
.cta a{
  color: #000;
}

h1{
  margin-bottom: 0;
}
h2{
  margin-top: 0;
}

h1 span{
  font-size: 50%;
}
h2 mark{
background-color: #eee;
padding: 0 .5rem;
}


.build{
  position: absolute;
  top:0;
  left: 0;
  font-size: 9px;
  padding: 5px;
}

.github{
  position: absolute;
  top:0;
  right:0;
}

.ribbon {
  background-color: #333;
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  right: -50px;
  top: 40px;
  transform: rotate(45deg);
  box-shadow: 0 0 10px #888;
}
.ribbon a {
  border: 1px solid #999;
  color: #fff;
  display: block;
  font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 1px 0;
  padding: 5px 90px;
  text-align: center;
  text-decoration: none;
  /* shadow */
  text-shadow: 0 0 5px #444;
}

button{
  margin: 0;
}

.innwrapper{
  width: 100%;
  text-align: center;
}


.grid{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.main .grid{
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.sample{
  width: 100%;
}

.samples{
  margin-top:3rem;
}

.samples h2{
  margin-bottom: 0;
  margin-top: 1rem;
}
.samples p{
  margin-top: 0;
  padding: 0 1rem;
}

@media screen and (min-width: 420px){
  .sample{
    width: 50%;
  }
}

@media screen and (min-width: 760px){
  .sample{
    width: 33.3333333333%;
  }
  .main .sample{
    width: 50%;
  }
  .inner{
    padding: 0 2rem;
  }
}
@media screen and (min-width: 1280px){
  .sample{
    width: 33.3333333333%;
  }
  .inner{
    padding: 0 2rem;
  }
  .innwrapper{
    width: 75%;
  }
}
</style>