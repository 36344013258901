<template>
    <div class="panel" :class="{expand:showWhats}">

        <div @click="blockEvt">
            <select v-model="currentEnv">
                <option disabled>Flavour:</option>
                <option value="markdown">Markdown</option>
                <option value="text">Text</option>
                <option value="javascript">Javascript</option>
                <option value="yaml">Yaml</option>
                <option value="json">JSON</option>
            </select>

            <!-- <input placeholder="Base URL" type="text" v-model="currentBaseUrl" @keyup.enter="onCurrentBase" @change="onCurrentBase" /> <a href="" @click.prevent="showWhats=true">What's Base URL?</a> -->
        </div>

        <div class="social">
            <a title="Library Github" href="https://github.com/presenta-software/presenta-lib" target="blank" class="icon github"><img src="@/assets/icons/github.svg" /></a>
            <a title="Author Twitter" href="https://twitter.com/fabiofranchino" target="blank" class="icon twitter"><img src="@/assets/icons/twitter.svg" /></a>
            <a title="Main Platform" href="https://presenta.cc" target="blank" class="icon presenta"><img src="@/assets/icons/presenta.svg" /></a>
        </div>

        <div class="links" @click="blockEvt">
            <p><a href="" @click.prevent="download">Download {{env}} document</a></p>
        </div>


        <div v-if="showWhats" class="whats">
            <div class="close"><a href="" @click.prevent="showWhats=false">close</a></div>
            <p>You can set a local (or remote) URL as base-url to serve assets from.</p>
            <p>All the assets' <b>relative</b> URL will be inferred and completed accordingly.</p>
            <p>Furthermore, this tool is a PWA, thus, it can run in offline mode.</p>
            <p>A couple of use cases to explain better:</p>
            <ol>
                <li>You want to use local files, then, run a local webserver pointing to your local assets folder.</li>
                <li>You want to use your assets published somewhere (i.e. Github), then, use the base URL of your server.</li>
            </ol>
            <p>Suppose a URL set to <i>http://localhost:9000/</i>, now you can write in the text editor a <b>relative</b> asset path to that URL, such as <i><b>myImage.jpg</b></i>, that will be transformed into <i><b>http://localhost:9000/myImage.jpg</b></i>.</p>
            <p>👀 Of course, sharing a presentation that requires local files, will work only for you.</p>
            <p><a href="" @click.prevent="showWhats=false">Got it!</a></p>

        </div>
    </div>
</template>


<script>
import { mapGetters, mapMutations } from 'vuex'
import * as Presenta from '@presenta/lib'
import stringify from '@/libs/stringify'

export default {
    data(){
        return{
            currentExample:null,
            currentEnv: '',
            currentColors: '',
            currentFonts: '',
            currentTransitions: '',
            allColors:[],
            allFonts:[],
            allTransitions:[],
            currentBaseUrl: '',

            showWhats:false
        }
    },
    mounted(){
        this.allColors = Presenta.colors
        this.allFonts = Presenta.fonts
        this.allTransitions = Presenta.transitions
        this.currentEnv = this.env
        
        this.currentBaseUrl = this.baseurl
    },
    watch:{
        currentEnv(v){
            this.setEnv(v)
        }
    },
    computed:{
        ...mapGetters(['env', 'project', 'colors', 'fonts', 'transitions', 'baseurl', 'all']),
    },
    methods:{
        ...mapMutations(['setEnv', 'setBase']),
        onCurrentBase(){
            this.setBase(this.currentBaseUrl)
        },
        download(){
            let project = this.all[this.env] //stringify(this.project, true)
            const url = window.URL.createObjectURL(new Blob([project]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `config.${this.env}`);
            document.body.appendChild(link);
            link.click();
        },
        blockEvt(e){
            e.preventDefault()
            e.stopPropagation()
        }
    }
}
</script>



<style scoped>
.panel{
    position: absolute;
    background-color: white;
    padding: 1rem;
    top: 62px;
    z-index: 1999;

    width: 100%;
    height: auto;
    max-width: 550px;
    box-shadow: 10px 10px 0 black;
}
.expand{
    min-height: 375px;
}

.whats{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    background-color: white;
    padding: .5rem 1rem;
    line-height: 1.25rem;
}
.whats p{
    margin: 0;
    margin-bottom: .5rem;
}
.close{
    text-align: right;
    font-size: .8rem;
}



select, input{
    margin-right: 10px;
    width: 100%;
    margin-bottom: 15px;
}
option{
    text-transform: capitalize;
}
select, button, input{
    font-size:1.1rem;
    border:none;
    background-color: white;
    border:1px solid black;
    box-shadow: 5px 5px 0 black;
    padding: .2rem .5rem;
}
select:focus, button:focus{
    outline: none !important;
}




.social{
    padding-top: 1rem;
    display: flex;
    align-items: center;
}
.icon{
    opacity: 1;
}
.icon img{
    width: 35px;
    height: 35px;
    vertical-align: text-top;
}
.icon:hover{
    opacity: .5;
}

.icon.twitter{
    margin-left:8px;
}

.icon.presenta{
    margin-left:8px;
}


.dropdown{
    display: none;
    position: fixed;
    width: 100%;
    left: 0;
    top: 65px;
    z-index: 9999;
    padding: 1rem;
    background-color: white;
    border:3px solid black;
}

.links{
    padding-top:1rem;
}
.links p{
    margin: 0;
    padding: .1rem 0;
}
a{
    color: #000;
}
@media screen and (min-width:420px) {
    input{
        width: 50%;
    }
}
</style>