<template>
    <div class="wrap">
        <div ref="wrap" class="presentaExampleWrapper"></div>
        <p v-if="!hideLink && prj"><router-link :to="'/view/' + id">View "{{title}}"</router-link> [<router-link :to="'/peek/' + id">Edit</router-link>]</p>
    </div>
</template>



<script>
import * as Presenta from '@presenta/lib'
import { mapGetters, mapMutations } from 'vuex'

export default {
    props:{
        project:Object,
        item:Object,
        hideLink:Boolean
    },
    data(){
        return{
            instanced:null,
            prj:null,
            id:null
        }
    },
    mounted(){
        if(!this.cache[this.item.id]){
            this.fetch()
        }else{
            this.build(this.cache[this.item.id])
        }

    },
    beforeDestroy(){
        if(this.instanced) this.instanced.destroy()
        this.instanced = null
    },
    computed:{
        ...mapGetters(['cache']),
        title(){
            return this.prj && this.prj.title ? this.prj.title : 'Untitled'
        }
    },
    methods:{
        ...mapMutations(['setCache']),
        async fetch(){
            const endpt = process.env.VUE_APP_CDN_FRONTEND_BASE
            const data = await fetch(endpt + this.item.id + '.json').then(res => res.json())
            this.build(data)
            this.setCache({key:this.item.id, doc:data})
        },
        build(project){
            try{
                new Presenta(this.$refs.wrap, project).then(scene => {
                    this.instanced = scene
                })
                this.prj = project
                this.id = project.filename
            }catch(e){
                console.log(e)
            }
        }
    }
}
</script>



<style scoped>
.wrap{
    height: 100%;
    position: relative;
    padding: 1rem;
}
.presentaExampleWrapper{
    /* outline: 1px solid black;
    margin-bottom: 10px; */
    box-shadow: 0 0 10px #cccccc;
}
.presentaSceneWrapper >>> div:focus {
  outline: none;
}

p{
    font-size: .8rem;
}
</style>