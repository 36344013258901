<template>
  <div class="present">
    <PreviewView class="view" />
  </div>
</template>



<script>
import PreviewView from '@/components/PreviewView'

export default {
  components:{
    PreviewView
  }
}
</script>


<style scoped>
.present{
  width: 100%;
  height: 100%;
}
</style>