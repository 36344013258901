<template>
    <div class="header" @click="toggleShowPanel">

        <div class="left">
            <button  @click="$router.push('/')">home</button>
            <button  v-if="!readonly && !prj"  @click.stop.prevent="toggleShowPanel">options</button>
        </div>
        
        <div class="right" v-if="!readonly && !prj">
            <p v-if="isBroken" class="msg err">👇 There's an error in the source 😩, Rendering is blocked.</p>
            <button v-if="isOnline" @click="$router.push('/share')">share</button>
            <button @click="$router.push('/present')">present</button>
        </div>

        <div class="right" v-if="readonly">
            <p v-if="!isBroken && isDirty" class="msg">Ephemeral edit just for learning. Fork to keep it locally 👉</p>
            <p v-if="isBroken" class="msg err">👇 There's an error in the source 😩, Rendering is blocked.</p>
            <button  @click="fork">fork</button>
            <button @click="$router.push('/present')">present</button>
        </div>

        <div class="right" v-if="prj">
            <button  @click="viewcode">edit</button>
            <button @click="viewpresent">present</button>
        </div>
    </div>
</template>




<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    props:{
        readonly:Boolean,
        isDirty:Boolean,
        isBroken:Boolean,
        prj:Object
    },
    data(){
        return{
            isOnline: true,
            showPanel:false,
        }
    },
    mounted(){
        this.isOnline = navigator.onLine
    },
    computed:{
        ...mapGetters(['project']),
        upper(){
            return this.showPanel ? {uprot:true} : {}
        },
        dwper(){
            return this.showPanel ? {dwrot:true} : {}
        },
        mdper(){
            return this.showPanel ? {mdopa:true} : {}
        }
    },
    methods:{
        ...mapMutations(['save', 'setProject']),
        toggleShowPanel(){
            this.showPanel = !this.showPanel
            this.$emit('showPanel', this.showPanel)
        },
        blockEvt(e){
            e.preventDefault()
            e.stopPropagation()
        },
        fork(){
            this.save()
            this.$router.push('/edit/')
        },
        viewcode(){
            this.$router.push('/peek/' + this.$route.params.id)
        },
        viewpresent(){
            this.setProject(this.prj)
            this.$router.push('/present')
        }
    }
}
</script>




<style scoped>
.header{
    padding: 1rem;
    display: flex;
    border-bottom: 3px solid black;
}
.right{
    display: flex;
}

.left button:first-child{
    margin: 0;
}

.header button{
    font-size: 0.9rem;
}


.left{
    flex:1;
    padding: 0;
    display: flex;
}

.msg{
    margin: 0;
    display: flex;
    align-items: center;
    font-size: .8rem;
    background-color: #000;
    color: white;
    padding: 0 .5rem;
    display: none;
}
.err{
    background-color: rgb(180,0,0);
}

@media screen and (min-width:420px) {
    .header button{
        font-size: 1.1rem;
    }
    .msg{
        display: flex;
    }
}
</style>