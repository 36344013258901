
export default str => {
  let project = null
  try {
    project = JSON.parse(str)
  } catch (e) {
    // no bueno
  }

  return project
}
