import Vue from 'vue'
import Vuex from 'vuex'
import commonProps from '@/libs/commonProps.js'
import defaults from '@/libs/defaults.js'

Vue.use(Vuex)

const lsk = 'play.presenta'

let ls = null
let lsState
try {
  ls = localStorage.getItem(lsk)
  lsState = ls ? JSON.parse(ls) : JSON.parse(JSON.stringify(defaults.state))
} catch (err) {
  console.log(err)
  lsState = JSON.parse(JSON.stringify(defaults.state))
}

lsState.empty = !ls
lsState._cache = {}
lsState._samples = []
lsState.showIndex = 0
if (!lsState.lastEnv) lsState.lastEnv = lsState.env
if (!lsState.lastCode) lsState.lastCode = lsState[lsState.env]

const repl = (key, value) => {
  if (key === 'project' || key.indexOf('_') === 0) return undefined
  return value
}

lsState.project = null

/* eslint-disable no-new */
const store = new Vuex.Store({
  state: lsState,
  getters: {
    empty (state) {
      return state.empty
    },
    code (state) {
      return state[state.env]
    },
    lastCode (state) {
      return state.lastCode
    },
    cache (state) {
      return state._cache
    },
    samples (state) {
      return state._samples
    },
    showIndex (state) {
      return state.showIndex
    },
    env (state) {
      return state.env
    },
    lastEnv (state) {
      return state.lastEnv
    },
    project (state) {
      return state.project
    },
    colors (state) {
      return state.colors
    },
    fonts (state) {
      return state.fonts
    },
    transitions (state) {
      return state.transitions
    },
    baseurl (state) {
      return state.baseurl
    },
    all (state) {
      return state
    }
  },
  mutations: {
    setCode (state, v) {
      state[state.env] = v
    },
    setEnv (state, v) {
      state.env = v
    },
    setBase (state, v) {
      state.baseurl = v
      this.commit('setProject', state.project)
    },
    setCache (state, v) {
      state._cache[v.key] = v.doc
    },
    setShowIndex (state, v) {
      state.showIndex = v
    },
    setSamples (state, v) {
      state._samples = v
    },
    setProject (state, v) {
      if (v && v !== undefined) {
        commonProps(v)
        if (state.project) {
          state.project.scenes.forEach((s, i) => {
            if (v.scenes[i]) v.scenes[i]._editing = s._editing
          })
        }
        state.project = v
      }
    },
    save (state) {
      state.empty = false
      state.lastEnv = state.env
      state.lastCode = state[state.env]
      localStorage.setItem(lsk, JSON.stringify(state, repl))
    }
  }
})

export default store
