<template>
    <div class="gallery">
        <div @click.prevent="edit(project)" v-for="(project,index) in projects" :key="project._uid">
            <VuePresentaScene class="scene"
                :index="index"
                :project="project"/>
        </div>
    </div>
</template>



<script>
import VuePresentaScene from '@/components/VuePresentaScene'

export default {
    components:{
        VuePresentaScene
    },
    data(){
        return {
            projects:[]
        }
    },
    mounted(){
        const endpt = process.env.VUE_APP_LAMBDA_ENDPOINT + 'list'

        fetch(endpt)
            .then(res => res.json())
            .then(data => {
                const latest = data.list.filter((d, i) => i < 10)

                var all = []

                latest.forEach(p => {
                    all.push(fetch(process.env.VUE_APP_CDN_FRONTEND_BASE + p.id + '.json').then(res => res.json()))
                })

                Promise.all(all)    
                    .then(data => {
                        console.log(data)
                        data.forEach(p => {
                            p._uid = 'id' + Math.random()
                            this.projects.push(p)
                        })
                    })
                
            })
    },
    methods:{
        edit(p){
            this.$router.push('/view/' + p.filename)
        }
    }
}
</script>


<style scoped>
.gallery{
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.scene{
    width: 100%;
    margin: 0;
}
</style>