import Vue from 'vue'
import VueRouter from 'vue-router'
import Editor from '../views/Editor.vue'
import Peek from '../views/Peek.vue'
import Present from '../views/Present.vue'
import Share from '../views/Share.vue'
import View from '../views/View.vue'
import V2 from '../views/V2.vue'
import V3 from '../views/V3.vue'
import Gallery from '../views/Gallery.vue'
import Home from '../views/Home.vue'
import Import from '../views/Import.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

  {
    path: '/edit',
    name: 'Edit',
    component: Editor
  },

  {
    path: '/present',
    name: 'Present',
    component: Present
  },
  {
    path: '/share',
    name: 'Share',
    component: Share
  },
  {
    path: '/view',
    redirect: to => '/'
  },
  {
    path: '/view/:id',
    name: 'view',
    component: View
  },
  {
    path: '/v2',
    name: 'v2',
    component: V2
  },
  {
    path: '/v3',
    name: 'v3',
    component: V3
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: Gallery
  },

  {
    path: '/peek/:id',
    name: 'Peek',
    component: Peek
  },

  {
    path: '/import/md',
    name: 'Import',
    component: Import
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
