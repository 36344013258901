export default {
  state: {
    baseurl: '',
    yaml: '',
    javascript: '',
    markdown: '',
    json: '',
    text: '',
    lastCode: '',
    lastEnv: '',
    env: 'markdown',
    colors: 'blue',
    fonts: 'goose',
    transitions: 'fadeIn'
  }
}
