<template>
    <div class="share panel">
        <div class="inner">
            
            <div class="center confirm" v-if="status==='confirm'">
                <div><p>Publish this presentation:</p></div>
                <div class="preso" ref="presenta"></div>
                <div><input v-model="title" type="text" placeholder="Set a title" /></div>
                <div><textarea v-model="description" placeholder="Set a description"></textarea></div>
                <div><button @click="confirm">Go ahead and share!</button></div>
                <div><a href="" @click.prevent="$router.push('/edit')">I've changed my mind</a></div>
            </div>


            <div class="center saving" v-if="status==='saving'">
                Just a sec...
            </div>
            

            <div class="center error" v-if="status==='error'">
                <p>There's an error 😿</p>
                <p><small>{{errorMessage}}</small></p>
            </div>


            <textarea id="vclipboard" style="position:absolute;top:-1000px;"></textarea>
        </div>
    </div>
</template>



<script>
import * as Presenta from '@presenta/lib'
import { mapGetters } from 'vuex'
import stringify from '@/libs/stringify'
import ShortUUID from 'short-unique-id'
const uid = new ShortUUID()

export default {
    data(){
        return{
            status: 'confirm',
            shareLink:'',
            copied:false,
            errorMessage:'',
            title:'',
            description:''
        }
    },
    computed:{
        ...mapGetters(['project', 'all'])
    },
    mounted(){
        if(!this.project){
            return this.$router.push('/')
        }else{
            let p = JSON.parse(stringify(this.project))
            p.adapt = true
            new Presenta(this.$refs.presenta, p)
        }
    },
    methods:{
        confirm(){
            const endpt = process.env.VUE_APP_LAMBDA_ENDPOINT + 'upload-to-s3'
            
            const p = JSON.parse(stringify(this.project, true))
            const s = JSON.parse(stringify(this.all, true))
            p._source = s

            p.created_at = new Date()
            p.filename = uid.randomUUID(13)
            p.title = this.title
            p.description = this.description

            this.status = 'saving'

            fetch(endpt, {
                method:'POST',
                body: JSON.stringify({ fileName: p.filename + '.json', fileType: 'application/json' })
            }).then(resp => resp.json())
            .then(data => {
                return fetch(data.uploadURL, {
                    method: "PUT",
                    body: new Blob([JSON.stringify(p)], {type: 'application/json'})
                })
            })
            .then(() => {
                    this.$router.push('/view/' + p.filename)
            }).catch(err => {
                console.log('ERROR saving', err)
                this.status = 'error'
                this.errorMessage = err
            })

        }
    }
}
</script>



<style scoped>
.center{
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1.5rem;
    text-align: center;
}

.preso{
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 220px;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
}

@media screen and (min-width: 420px) {
    .preso{
        width: 400px;
        height: 250px;
        border: 2px solid #000;
    }
}

.saving{
    background-color: black;
    color:white;
}
.saved{
    flex-direction: column;
}
.error{
    background-color: red;
    flex-direction: column;
    color:white;
}
.confirm a{
    font-size: 1.25rem;
}
button{
    font-size:1.5rem;
    margin: 1rem;
}
input, textarea{
    text-align: center;
    padding: .2rem .5rem;
    border: 2px dashed black;
}
a{
    color: #000;
}
p{
    margin: 0;
}

textarea{
    margin-top: 1rem;
}
</style>