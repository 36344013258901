<template>
  <div id="app">
    <router-view/>
    <div v-if="updateExists" class="update">This App has been updated. Close this window and re-open it to experience the new version.</div>
  </div>
</template>




<script>
import 'animate.css'
import './assets/style.css'
import Update from './mixins/update'

export default {
  name: 'App',
  mixins: [Update]
}
</script>


<style scoped>
#app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  box-shadow: 10px 10px 0 black;
  padding:0;
}

.update{
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.96);
  z-index: 99999;
}
</style>
