<template>
    <div class="wrap">
        <a :id="'s' + index" class="anchor"></a>
        <div ref="wrap" class="presentaSceneWrapper"></div>
        <div v-if="selected" class="selector"></div>
    </div>
</template>



<script>
import * as Presenta from '@presenta/lib'
import stringify from '@/libs/stringify'
import { mapGetters } from 'vuex'


export default {
    props:{
        scene:Object,
        project:Object,
        index:Number
    },
    data(){
        return{
            instancedScene:null
        }
    },
    computed:{
        getCls(){
            return this.scene && this.scene._editing ? {selected:true} : {}
        },
        selected(){
            return this.scene && this.scene._editing
        }
    },
    mounted(){
        this.build()
    },
    beforeDestroy(){
        if(this.instancedScene) this.instancedScene.destroy()
        this.instancedScene = null
    },
    methods:{
        build(){
            let p = JSON.parse(stringify(this.project))

            p.mode = 'preview'
            p.transition = false
            p.scenes = this.scene ? [this.scene] : p.scenes

            try{
                new Presenta(this.$refs.wrap, p).then(scene => {
                    this.instancedScene = scene
                })
            }catch(e){
                console.log(e)
            }
        }
    }
}
</script>



<style scoped>
.wrap{
    height: 100%;
    position: relative;
    padding: 1rem;
}
.presentaSceneWrapper{
    /* outline: 1px solid black;
    margin-bottom: 10px; */
    box-shadow: 0 0 10px #cccccc;
}
.presentaSceneWrapper >>> div:focus {
  outline: none;
}

.anchor{
    position: absolute;
    top:0;
}


.selector{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    border: 3px dashed #ccc;
    z-index: 999;
}
</style>